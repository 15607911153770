import ButtonArrow from "@components/shared/buttonArrow";
import CheckIcon from "@components/svg/checkIcon";
import React, { ReactElement, useState } from "react";
// import Image from "next/image";
import {
    doesOfferHaveALink,
    formatPhoneNumber,
    getPhoneNumber,
    replacePlaceholders,
} from "@lib/sharedUtils";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { affiliateOfferZones } from "@lib/shared/config";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";
import StarIcon from "@components/svg/starIcon";
import { ListingItem } from "@lib/shared/types";
import Chevron from "@components/svg/chevron";

export default function Offer({
    offer,
    index,
    logoUrl,
    buttonText,
    title,
    bulletedDescription,
    revenue,
    slug,
    isExternal,
    featuredOfferMessage,
    featuredOfferMessagePosition = "cta",
    ratingPosition = "cta",
    showIndex = false,
    showRating = false,
    showSummary = false,
    showSeparator = false,
}: {
    offer?: ListingItem;
    index: number;
    logoUrl?: string;
    buttonText?: string;
    title?: string;
    bulletedDescription?: string[];
    revenue?: string | number;
    slug?: string;
    isExternal: boolean;
    featuredOfferMessage?: string;
    featuredOfferMessagePosition?: "cta" | "top" | "banner";
    ratingPosition?: "cta" | "middle" | "middleWithSeparator";
    showIndex?: boolean;
    showRating?: boolean;
    showSummary?: boolean;
    showSeparator?: boolean;
}): ReactElement {
    const { isBot, visitDetails, category } = useDomainContext();
    const [clicked, setClicked] = useState(false);
    const [summaryExpanded, setSummaryExpanded] = useState(false);

    if (isBot) {
        return <></>;
    }

    const rating = offer?.score
        ? Number(offer?.score) * 2 > 10
            ? 10
            : Number(offer?.score) * 2
        : 5;

    const resolveWordedRating = (rating: number) => {
        if (rating >= 9.5) {
            return "Exceptional";
        }
        if (rating >= 8) {
            return "Excellent";
        }
        if (rating >= 7) {
            return "Great";
        }
        return "Good";
    };

    const handleClick = () => {
        setClicked(true);
    };
    const phoneNumber = getPhoneNumber(offer);

    const displayLinkAndPhoneNumber = offer ? doesOfferHaveALink(offer) : false;

    const ratingBlock =
        showRating && rating ? (
            <div className={styles["rating"]}>
                <span className={styles["worded"]}>
                    {resolveWordedRating(rating)}
                </span>{" "}
                <span className={styles["numbered"]}>{rating}</span>
            </div>
        ) : (
            <></>
        );

    return (
        <div
            className={`${styles["listing-block"]} ${
                (index === 0 && featuredOfferMessage && styles["featured"]) ||
                ""
            } ${
                index === 0 &&
                featuredOfferMessage &&
                featuredOfferMessagePosition === "banner"
                    ? styles["listingBlockOverflow"]
                    : ""
            }`}
        >
            {index === 0 &&
            featuredOfferMessage &&
            featuredOfferMessagePosition === "banner" ? (
                <div className={styles["bannerFeaturedOfferMessage"]}>
                    <span>
                        {replacePlaceholders(featuredOfferMessage ?? "", {
                            category: category?.name,
                            region: visitDetails?.region,
                            city: visitDetails?.city,
                        })}
                    </span>
                </div>
            ) : (
                <></>
            )}
            {((index === 0 &&
                featuredOfferMessage &&
                featuredOfferMessagePosition === "top") ||
                showIndex) && (
                <div className={styles["offer-index"]}>
                    {showIndex && (
                        <div className={styles.index}>
                            {String(index + 1).padStart(2, "0")}
                        </div>
                    )}
                    {index === 0 &&
                    featuredOfferMessage &&
                    featuredOfferMessagePosition === "top" ? (
                        <span
                            className={
                                styles["featured-offer-message-top"] +
                                ` ${!showIndex ? "ml-10 mt-2" : ""}`
                            }
                        >
                            {replacePlaceholders(featuredOfferMessage ?? "", {
                                category: category?.name,
                                region: visitDetails?.region,
                                city: visitDetails?.city,
                            })}
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            )}

            {index === 0 && featuredOfferMessage && !showIndex && (
                <div className={styles["order"]}>{<StarIcon />}</div>
            )}
            <div className={styles["logo"]}>
                <AffiliateOfferUrl
                    zone={affiliateOfferZones.PAGE_BUILDER}
                    target={!phoneNumber ? "_blank" : undefined}
                    offer={offer}
                    customHref={
                        phoneNumber ? `tel:${phoneNumber ?? ""}` : undefined
                    }
                >
                    <img src={logoUrl} alt="logo" />
                </AffiliateOfferUrl>

                {ratingBlock}
            </div>

            <div
                className={`${styles["content"]} ${
                    ratingPosition === "middle" ||
                    ratingPosition === "middleWithSeparator"
                        ? styles["contentWithRatingMiddle"]
                        : ""
                } ${showSeparator ? styles["contentShowSeparator"] : ""}`}
            >
                <div
                    className={styles["title"]}
                    dangerouslySetInnerHTML={{ __html: title ?? "" }}
                ></div>

                <div className={styles["features"]}>
                    <ul>
                        {bulletedDescription
                            ?.slice(0, 5)
                            ?.map((feature, idx) => (
                                <li key={idx}>
                                    <span className="children:h-3 children:w-3">
                                        <CheckIcon />
                                    </span>

                                    <span
                                        className="ml-2"
                                        dangerouslySetInnerHTML={{
                                            __html: feature,
                                        }}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {ratingPosition === "middle" ||
            ratingPosition === "middleWithSeparator" ? (
                <div
                    className={`${styles["ratingMiddle"]} ${
                        ratingPosition === "middleWithSeparator"
                            ? styles["middleWithSeparator"]
                            : ""
                    }`}
                >
                    {ratingBlock}
                </div>
            ) : (
                <></>
            )}

            <div
                className={`${styles["cta"]} ${
                    clicked ? styles["clicked"] : ""
                }`}
                style={{ textAlign: "center" }}
            >
                {ratingPosition === "cta" ? ratingBlock : <></>}

                {revenue && <span className="mb-1 block">${revenue}</span>}
                {isExternal ? (
                    <a
                        target="_blank"
                        onClick={handleClick}
                        href={`/offer/${slug ?? ""}&isExternalOffer=true`}
                        rel="noreferrer"
                        className={`${styles["button"]} before:absolute before:w-full before:h-full before:left-0 before:top-0`}
                    >
                        {!clicked ? (
                            <span className={styles["main"]}>
                                {replacePlaceholders(buttonText ?? "", {
                                    category: category?.name,
                                    region: visitDetails?.region,
                                    city: visitDetails?.city,
                                })}
                                <ButtonArrow />
                            </span>
                        ) : (
                            <span className={styles["check"]}>
                                <CheckIcon />
                            </span>
                        )}
                    </a>
                ) : (
                    <>
                        <AffiliateOfferUrl
                            zone={affiliateOfferZones.PAGE_BUILDER}
                            target={!phoneNumber ? "_blank" : undefined}
                            className={`text-center py-3 rounded-lg lg:px-6 ${
                                !displayLinkAndPhoneNumber
                                    ? `before:absolute before:w-full before:h-full before:left-0 before:top-0`
                                    : ""
                            } ${styles.button}`}
                            offer={offer}
                            customHref={
                                phoneNumber
                                    ? `tel:${phoneNumber ?? ""}`
                                    : undefined
                            }
                        >
                            <span>
                                {formatPhoneNumber(phoneNumber) ?? buttonText}

                                <ButtonArrow />
                            </span>
                        </AffiliateOfferUrl>

                        {displayLinkAndPhoneNumber && (
                            <AffiliateOfferUrl
                                zone={affiliateOfferZones.PAGE_BUILDER}
                                className={`text-left lg:text-center py-3 rounded-lg ${styles.link}`}
                                offer={offer}
                                target="_blank"
                            >
                                <span>View Deals</span>
                            </AffiliateOfferUrl>
                        )}
                    </>
                )}

                {index === 0 &&
                featuredOfferMessage &&
                featuredOfferMessagePosition === "cta" ? (
                    <span className={styles["featured-offer-message"]}>
                        {replacePlaceholders(featuredOfferMessage ?? "", {
                            category: category?.name,
                            region: visitDetails?.region,
                            city: visitDetails?.city,
                        })}
                    </span>
                ) : (
                    <></>
                )}
            </div>
            {showSummary && offer?.previewDescription && (
                <div className={styles["summary-container"]}>
                    <div
                        onClick={() => {
                            setSummaryExpanded((prev) => !prev);
                        }}
                        className={styles["summary-toggle"]}
                    >
                        {summaryExpanded ? "Hide Summary" : "Show Summary"}
                        <Chevron
                            className={`${
                                summaryExpanded
                                    ? styles["chevron-up"]
                                    : styles["chevron-down"]
                            }`}
                        />
                    </div>
                    <div
                        className={`${styles["summary"]} ${
                            summaryExpanded ? styles["expanded"] : ""
                        }`}
                        dangerouslySetInnerHTML={{
                            __html: offer.previewDescription,
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
}
